import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/8.png"
import Adam from "../images/photos/adam.jpg"

const LECTURER = [
  {
    src: Adam,
    alt: "",
    personName: "Adam Šilhan",
    linkedInProfile: "//linkedin.com/in/adamsilhan",
    text: [
      "Zaměřuje se na interpretační část – jak správně s daty pracovat. V této oblasti dlouhodobě mentoroval například interní analytický tým v Kiwi. Jako Google trenér pro atribuce školil Google Attribution Academy. Učí webovou analytiku na Masarykově univerzitě.",
    ],
  },
]

const PohledMarketera: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper
        title="Pohled marketéra – vyhodnocování"
        ogImage={OgImage}
      />

      <SyllabusHeader
        orangeText="Pohled marketéra –"
        blackText="vyhodnocování"
        numberOfLesson="8/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>jak rozpadnout problém</div>
        <div>
          alternativy měření problému, který napřímo změřit neumíme (hledání
          rozumných proxies)
        </div>
        <div>atribuce</div>
        <div>inkrementalita</div>
        <div>řízené experimenty</div>
        <div>základy CLV, customer base</div>
        <div>hlavní saasové metriky – MRR, churn</div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default PohledMarketera
